<template>
  <div class="stacks-page pa-0">
    <CyWizardServiceCatalog/>
  </div>
</template>

<script>
import { constructBreadcrumb } from '@/utils/helpers'
import CyWizardServiceCatalog from '@/components/wizard/service-catalog.vue'

export default {
  name: 'CyPageStacks',
  components: {
    CyWizardServiceCatalog,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.stacks'), [
      {
        label: this.$t('routes.stacksSection'),
        name: 'stacksSection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.stacksSection'),
      description: {
        text: this.$t('routes.stacksSectionDescription'),
        link: this.$docLinks.stacks.overview,
      },
    }
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.stacks',
      },
      es: {
        title: '@:routes.stacks',
      },
      fr: {
        title: '@:routes.stacks',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.stacks-page {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  padding: 24px;
}
</style>
